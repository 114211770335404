import Logo from '@assets/images/shopagain_logo.png';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import isAdminApp from '@utils/isAdminApp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    },

    logo: {
      // maxWidth: 100,
      height: '40px',
      marginRight: '10px',
      borderRadius: 5,
      padding: '4px',
      backgroundColor: 'white',

      [theme.breakpoints.down('sm')]: {
        height: '30px',
      }
    },

    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(11),
    },
  }),
);

export default function OutsideNavbar(props: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color={isAdminApp ? "secondary" : "primary"} variant="outlined">
        <Toolbar>
          <img src={Logo} alt="ShopAgain logo" className={classes.logo} />
          {
            isAdminApp && (
              <Typography variant="h6">
                Admin
              </Typography>
            )
          }
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        {props.children}
      </main>
    </div>
  );
}
