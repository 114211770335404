import { setUserId } from '@amplitude/analytics-browser';
import history from '@components/router/history';
import * as Sentry from "@sentry/react";
import auth from '@services/auth';
import isAdminApp from '@utils/isAdminApp';
import getSymbolFromCurrency from 'currency-symbol-map';
import allowAmplitude from 'src/amplitude/allowAmplitude';
import { StateCreator } from "zustand";
import { removeLocalStorage } from './UIState';
import useStore from './useStore';

export interface AuthState {
  user: any,
  userLoading: boolean,

  login: (email: string, password: string, token?: string) => Promise<void>,
  logout: () => Promise<void>,
  refreshUser: () => Promise<any>,
  modifyUser: (user: any) => void
}



export const createAuthStore: StateCreator<AuthState> = (set, get) => ({
  user: null,
  userLoading: true,

  login: async (email, password, token = '') => {
    const refreshUser = get().refreshUser
    await auth.login(email, password, token)
    await refreshUser()
  },

  logout: async () => {
    await auth.logout()
    history.push('/')
    removeLocalStorage("show_sms_onboarding_alert")
    set(state => ({
      user: null,
      userLoading: false
    }))
  },

  refreshUser: async () => {
    try {
      const userResponse: any = await auth.getUser()
      set(state => ({
        user: userResponse,
        userLoading: false
      }))

      userResponse?.company?.id && sessionStorage.setItem('currentcompany', userResponse?.company?.id);

      try {
        if ((window as any).$crisp) {
          (window as any).$crisp.push(["set", "user:email", userResponse?.user?.email]);
          if (userResponse?.company?.name) {
            (window as any).$crisp.push(["set", "user:nickname", userResponse?.company?.name]);
          }
        }
      }
      catch (e) {
        console.log(e)
      }

      if (userResponse?.user?.id) {
        if (allowAmplitude(userResponse?.user?.email)) {
          try {
            //amplitude
            setUserId(userResponse?.user?.id);
          }
          catch (e) {
            console.log(e)
          }

          try {
            Sentry.init({
              dsn: "https://855cf7acee364c72d261d8675a853e56@o4506876040773632.ingest.us.sentry.io/4506876120268800",
              integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                  maskAllText: false,
                  blockAllMedia: false,
                }),
              ],
              // Performance Monitoring
              tracesSampleRate: 1.0, //  Capture 100% of the transactions
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: [/^https:\/\/app\.shopagain\.io/],
              // Session Replay
              replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
              replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
          }
          catch (e) {
            console.log(e)
          }


        }

        if ((window as any).pendo && allowAmplitude(userResponse?.user?.email)) {
          (window as any).pendo.initialize({
            visitor: {
              id: userResponse?.user?.id   // Required if user is logged in, default creates anonymous ID
              // email:        // Recommended if using Pendo Feedback, or NPS Email
              // full_name:    // Recommended if using Pendo Feedback
              // role:         // Optional

              // You can add any additional visitor level key-values here,
              // as long as it's not one of the above reserved names.
            },

            account: {
              id: userResponse?.company?.id // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
              // name:         // Optional
              // is_paying:    // Recommended if using Pendo Feedback
              // monthly_value:// Recommended if using Pendo Feedback
              // planLevel:    // Optional
              // planPrice:    // Optional
              // creationDate: // Optional

              // You can add any additional account level key-values here,
              // as long as it's not one of the above reserved names.
            }
          });
        }


        try {
          const sleekPlanScriptId = "sleepplan-script";
          const script = document.getElementById(sleekPlanScriptId);
          if (!script) {
            const sleekPlanScript = document.createElement("script");
            sleekPlanScript.id = sleekPlanScriptId;
            sleekPlanScript.innerHTML = `
            window.$sleek = [];
            window.SLEEK_PRODUCT_ID = 16224006;
            (function () {
              d = document;
              s = d.createElement("script");
              s.src = "https://client.sleekplan.com/sdk/e.js";
              s.async = 1;
              d.getElementsByTagName("head")[0].appendChild(s);

              s.addEventListener("load", function () {
                $sleek.setUser ({
                  mail: '${userResponse?.user?.email}', 
                });
              });       
            })();
            `;
            document.head.appendChild(sleekPlanScript);
          }
        }
        catch (e) {
          console.log(e)
        }


      }


      return userResponse
    }
    catch (e) {
      set(state => ({
        user: null,
        userLoading: false
      }))
    }
  },

  modifyUser: (user) => {
    set(state => ({
      user: user,
      userLoading: false
    }))
  }
})

export const getCurrency = () => {
  return useStore.getState().user?.company?.currency ?? 'USD'
}

export const getCurrencySymbol = () => {
  return getSymbolFromCurrency(getCurrency())
}

export const getCompanyUserRole = () => {
  if (isAdminApp) {
    return "admin";
  }
  return (useStore.getState().user?.company?.company_role) as ("admin" | "campaign_manager");
}

export const getIfCompanyAdmin = () => {
  return getCompanyUserRole() === "admin";
}


export const isSmsAddOnPurchased = () => {
  const sms_add_on_ids = [2, 3, 10];
  const purchased_add_on_ids = useStore.getState().user?.company?.billing?.purchased_add_on_ids || [];
  return sms_add_on_ids.some(addon_id => purchased_add_on_ids.includes(addon_id));
}



export const isIndianSmsAddOnPurchased = () => {
  const indianSmsAddOnId = 3;
  const purchasedAddOnIds = useStore.getState().user?.company?.billing?.purchased_add_on_ids || [];
  return purchasedAddOnIds.includes(indianSmsAddOnId);
};
