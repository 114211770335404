import { debounce } from 'lodash';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { StateCreator } from "zustand";


export interface IUIState {
    notifications: any[],
    enqueueSnackbar: (action: {
        message: SnackbarMessage,
        options?: OptionsObject,
        key?: string
    }) => void,
    closeSnackbar: (action: any) => void,
    removeSnackbar: (action: any) => void,


    globalLoading: boolean,
    setGlobalLoading: (state: boolean) => void,

    darkMode: boolean,
    toggleDarkMode: () => void,
    show_sms_onboarding_popup: boolean,
    setShowSMSOnboardingPopup: (state: boolean) => void,

    setCheckingSyncStatus: (state: boolean) => void,
    checkingSyncStatus: boolean,
    showInfoTopbar: boolean,
    setShowInfoTopbar: (state: boolean) => void,
}

export const createUIStore: StateCreator<IUIState> = (set, get) => ({
    notifications: [],
    globalLoading: false,
    show_sms_onboarding_popup: true,

    setGlobalLoading: debounce((state: boolean) => {
        set({ globalLoading: state })
    }, 300),

    enqueueSnackbar: (action) => {

        set(state => {
            return {
                notifications: [
                    ...state.notifications,
                    {
                        ...action,
                        key: action?.key ?? new Date().getTime() + Math.random(),
                    },
                ],
            }
        })
    },

    closeSnackbar: (action) => {
        set(state => {
            return {
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };
        })
    },

    removeSnackbar: (action) => {
        set(state => {
            return {
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            }
        })
    },

    darkMode: getLocalStorage("darkMode") ?? false,
    toggleDarkMode: () => {
        setLocalStorage("darkMode", !get().darkMode);
        set(state => ({ darkMode: !state.darkMode }))
    },
    setShowSMSOnboardingPopup: (status: boolean) => {
        set(state => ({ show_sms_onboarding_popup: status }))
    },

    setCheckingSyncStatus: (status: boolean) => {
        set(state => ({ checkingSyncStatus: status }))
    },

    checkingSyncStatus: true,


    showInfoTopbar: false,
    setShowInfoTopbar: (status: boolean) => {
        set(state => ({ showInfoTopbar: status }))
    },
})


export const getLocalStorage = (key: any) => {
    try {
        return JSON.parse(window.localStorage.getItem(key) as string)
    } catch (e) {
        return null
    }
}
export const setLocalStorage = (key, value) =>
    window.localStorage.setItem(key, JSON.stringify(value));
export const removeLocalStorage = (key) =>
    window.localStorage.removeItem(key);
