import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { AuthState, createAuthStore } from './AuthState';
import { IUIState, createUIStore } from './UIState';

export type State = AuthState & IUIState

const useStore = create<State>()(
  subscribeWithSelector((set, get, store) => ({
    ...createAuthStore(set, get, store),
    ...createUIStore(set, get, store),
  }))
);

export default useStore
