import isAdminApp from '@utils/isAdminApp';
import api from './api'

export default {
    login(email, password, token) {
        const path = isAdminApp ? "admin_user/auth/login/" : "auth/login/";
        return api.post(path, {
            email: email,
            password: password,
            token: token
        })
    },
    logout() {
        return api.post(`auth/logout/`, {})
    },

    getUser() {
        const path = isAdminApp ? "admin_user/user/" : "user/";
        return api.get(path)
    },

    patchUser(data) {
        return api.patch('auth/user/', data)
    },

    registerUser(email, password, confirmPassword) {
        return api.post('auth/registration/', {
            email: email,
            password1: password,
            password2: confirmPassword
        })
    },
    resendVerificationEmail(email) {
        return api.post('auth/registration/resend-email/', {
            email: email,
        })
    },
    verifyEmail(key) {
        return api.post("auth/account-confirm-email/", {
            key: key
        })
    },

    passwordReset(uid, token, new_password1, new_password2) {
        return api.post("auth/password/reset/confirm/ ", {
            uid, token, new_password1, new_password2
        })
    },
    passwordResetRequest(email) {
        return api.post("auth/password/reset/", {
            email
        })
    },

    passwordChange(new_password1, new_password2, old_password) {
        return api.post("auth/password/change/", {
            new_password1, new_password2, old_password
        })
    }
}