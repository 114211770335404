import { init, setUserId, track } from '@amplitude/analytics-browser';
import useStore from '../store/useStore';
import allowAmplitude from './allowAmplitude';

const onlyOnProd = (fn: (...args: any) => any) => {
    return (...args: any) => {
        const userEmail = useStore.getState().user?.user?.email;
        if (allowAmplitude(userEmail)) {
            return fn(...args);
        }
        else {
            return;
        }
    }
}

export const initAmplitude = onlyOnProd(() => {
    init(process.env.REACT_APP_AMPLITUDE as string);
})

// export const setAmplitudeUserDevice = onlyOnProd(installationToken => {
//     amplitude.getInstance().setDeviceId(installationToken);
// });

export const setAmplitudeUserId = onlyOnProd(userId => {
    return setUserId(userId);
})

// export const setAmplitudeUserProperties = onlyOnProd(properties => {
//     amplitude.getInstance().setUserProperties(properties);
// })

export const sendAmplitudeData = onlyOnProd((eventType, eventProperties?: any) => {
    if ((window as any).pendo) {
        (window as any)?.pendo?.track?.(eventType, eventProperties);
    }
    return track(eventType, eventProperties)
})

export const amplitudeEvents = {
    edit_campaign: 'edit_campaign',
    create_new_campaign: 'create_new_campaign',
    save_campaign: 'save_campaign',
    save_and_exit_campaign: 'save_and_exit_campaign',
    save_and_launch_campaign: 'save_and_launch_campaign',
    campaign_next_click: 'campaign_next_click',

    opened_flow_editor: 'opened_flow_editor',
    save_flow: 'save_flow',

    dasbhoard_change_tab: 'dasbhoard_change_tab',
    dashboard_change_date_range: 'dashboard_change_date_range',
    dashboard_campaigns_report_sort: 'dashboard_campaigns_report_sort',

    opened_email_template_editor: 'opened_email_template_editor',
    saved_email_template: 'saved_email_template',

    opened_popup_editor: 'opened_popup_editor',
    create_new_popup_clicked: 'create_new_popup_clicked',
    create_popup_from_scratch_clicked: 'create_popup_from_scratch_clicked',
    create_popup_from_library_clicked: 'create_popup_from_library_clicked',
    save_popup_clicked: 'save_popup_clicked',

    sms_onboarding_completed: 'sms_onboarding_completed',

    discount_code_created: 'discount_code_created',
    discount_code_edited: 'discount_code_edited',

    user_profile_viewed: 'user_profile_viewed',

    list_create: 'list_create',
    list_edit: 'list_edit',
    list_delete: 'list_delete',

    segment_create: 'segment_create',
    segment_edit: 'segment_edit',
    segment_delete: 'segment_delete',
    opened_segment_editor: 'opened_segment_editor',
}