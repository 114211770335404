import 'moment-timezone';
import Router from './components/router'
import { ThemeProvider, StyledEngineProvider, responsiveFontSizes } from '@mui/material/styles';
import { alpha, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { AlertPopupProvider } from './contexts/AlertPopupContext';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';


import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';
import useStore from './store/useStore';
import Notifier from '@components/other/Notifier';
import Loading from '@components/other/Loading';


export const SHOW_CANVAS_ID = false
export const DEMO_COMPANY_ID = "8c76b40d-2c27-4bca-b0c4-b78b71a2eb30"


// handle case when two active tabs have two different companies
// and one of them opens a link in new tab
// new tab should have the same company as tab from which it was opened
window.addEventListener('focus', () => {
  const currentSessionStorageCompanyId = sessionStorage.getItem('currentcompany')
  if (currentSessionStorageCompanyId) {
    localStorage.setItem('currentcompany', currentSessionStorageCompanyId)
  }
});


export const gray = {
  50: 'hsl(220, 35%, 97%)',
  100: 'hsl(220, 30%, 94%)',
  200: 'hsl(220, 20%, 88%)',
  300: 'hsl(220, 20%, 80%)',
  400: 'hsl(220, 20%, 65%)',
  500: 'hsl(220, 20%, 42%)',
  600: 'hsl(220, 20%, 35%)',
  700: 'hsl(220, 20%, 25%)',
  800: 'hsl(220, 30%, 6%)',
  900: 'hsl(220, 35%, 3%)',
};

export const orange = {
  50: 'hsl(45, 100%, 97%)',
  100: 'hsl(45, 92%, 90%)',
  200: 'hsl(45, 94%, 80%)',
  300: 'hsl(45, 90%, 65%)',
  400: 'hsl(45, 90%, 40%)',
  500: 'hsl(45, 90%, 35%)',
  600: 'hsl(45, 91%, 25%)',
  700: 'hsl(45, 94%, 20%)',
  800: 'hsl(45, 95%, 16%)',
  900: 'hsl(45, 93%, 12%)',
};

const defaultTheme = createTheme();
function App() {

  const darkMode = useStore(store => store.darkMode)
  let theme = useMemo(() => responsiveFontSizes(
    createTheme({
      shape: {
        borderRadius: 6,
      },
      typography: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        h1: {
          fontSize: defaultTheme.typography.pxToRem(48),
          fontWeight: 600,
          lineHeight: 1.2,
          letterSpacing: -0.5,
        },
        h2: {
          fontSize: defaultTheme.typography.pxToRem(36),
          fontWeight: 600,
          lineHeight: 1.2,
        },
        h3: {
          fontSize: defaultTheme.typography.pxToRem(30),
          lineHeight: 1.2,
        },
        h4: {
          fontSize: defaultTheme.typography.pxToRem(24),
          fontWeight: 600,
          lineHeight: 1.5,
        },
        h5: {
          fontSize: defaultTheme.typography.pxToRem(20),
          fontWeight: 600,
        },
        h6: {
          fontSize: defaultTheme.typography.pxToRem(18),
          fontWeight: 600,
        },
        subtitle1: {
          fontSize: defaultTheme.typography.pxToRem(18),
        },
        subtitle2: {
          fontSize: defaultTheme.typography.pxToRem(14),
          fontWeight: 500,
        },
        body1: {
          fontSize: defaultTheme.typography.pxToRem(14),
        },
        body2: {
          fontSize: defaultTheme.typography.pxToRem(14),
          fontWeight: 400,
        },
        caption: {
          fontSize: defaultTheme.typography.pxToRem(12),
          fontWeight: 400,
        },
      },
      palette: {
        mode: darkMode ? 'dark' : 'light',
        // text: {}, // Keep this if you have custom text colors
        primary: {
          main: darkMode ? '#eceef8' : '#7743A3',
        },
        secondary: {
          main: '#F4263E',
        },
        // divider: alpha(gray[300], 0.4),
        text: {
          primary: gray[800],
          secondary: gray[600],
          warning: orange[400],
        },
        action: {
          hover: alpha(gray[200], 0.2),
          selected: `${alpha(gray[200], 0.3)}`,
        },
        baseShadow:
          'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px',
        background: {
          // default: 'hsl(0, 0%, 99%)',
          // paper: 'hsl(220, 35%, 97%)',
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'capitalize',
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              fontSize: defaultTheme.typography.pxToRem(14), // Default font size for all tabs
              textTransform: 'none', // Remove uppercase
            },
          },
        },
      },
    })
  ), [darkMode]);

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Loading />
        <CssBaseline />


        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          maxSnack={3}
        >
          <Notifier />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AlertPopupProvider>
              <div className="App">
                <Router />
              </div>
            </AlertPopupProvider>
          </LocalizationProvider>
        </SnackbarProvider>


      </ThemeProvider>
    </StyledEngineProvider>
  </>;
}

export default App;

