import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { createContext, useState, useContext, useMemo, useCallback } from "react";

export const AlertPopupContext = createContext()

export const AlertPopupProvider = ({ children }) => {
    const [options, setOptions] = useState({
        open: false,
        actions: []
    })

    const closePopup = () => {
        setOptions(options => ({ ...options, open: false }))
    }

    const callbackWrapper = (callback) => {
        return () => {
            callback()
            closePopup()
        }
    }


    const showAlertPopup = useCallback((options) => {
        setOptions({ open: true, ...options })
    }, [])

    return (
        <AlertPopupContext.Provider value={showAlertPopup}>
            <Dialog open={options.open}>
                <DialogTitle>{options.title}</DialogTitle>

                <DialogContent dividers>
                    {options.dialogContent}
                </DialogContent>
                <DialogActions>
                    {options.actions && options.actions.length ? options.actions.map(action => {
                        return (
                            <Button key={action.text} color="primary" onClick={callbackWrapper(action.onPress ? action.onPress : closePopup)} {...action.buttonProps}>
                                {action.text}
                            </Button>
                        )
                    }) : (
                        <Button color="primary" onClick={closePopup}>
                            OK
                        </Button>
                    )
                    }

                </DialogActions>
            </Dialog>
            {useMemo(() => children, [])}
        </AlertPopupContext.Provider >
    )
}


export const useAlertPopupService = () => useContext(AlertPopupContext);

