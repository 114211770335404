import { useEffect } from "react"

export default () => {
    useEffect(() => {
        const url = new URL("/woocommerce/auth/", process.env.REACT_APP_API_ROOT)
        window.location.href = url.toString() + window.location.search;
    }, [])

    return (
        null
    )
}
