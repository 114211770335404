import {
    Router,
    Switch,
    Route,
    useLocation,
    Redirect,
} from "react-router-dom";

import { Suspense, useEffect, useRef } from 'react';
import { lazy } from '@loadable/component'
import { QueryParamProvider } from 'use-query-params';
import history from "./history";
import SuspenseLoading from "@components/other/SuspenseLoading";
import OutsideRoute from "./OutsideRoute";
import PrivateRoute from './MainApp/PrivateRoute';
import isAdminApp from "@utils/isAdminApp";
import useStore from "src/store/useStore";
import setCurrentCompany from "@utils/setCurrentCompany";
import Woocommerce from "@views/other/Woocommerce";
import MaintenancePage from "@views/main/MaintenancePage";

let PrivateRouter = null;

if (isAdminApp) {
    PrivateRouter = lazy(() => import('./Admin/AdminPrivateRouter'))
}
else {
    PrivateRouter = lazy(() => import('./MainApp/MainPrivateRouter'));
}

//public routes
const Login = lazy(() => import('../../views/auth/Login'));
const PasswordResetConfirm = lazy(() => import('../../views/auth/PasswordResetConfirm'));
const PasswordResetRequest = lazy(() => import('../../views/auth/PasswordResetRequest'));

const ShopifyRegistration = lazy(() => import('@components/other/ShopifyRegisteristration'));
const ShopifyCallback = lazy(() => import('../../components/viewComponents/Settings/ShopifyCallback'));
const Register = lazy(() => import("../../views/auth/Register"));
const VerifyEmail = lazy(() => import('../../views/auth/VerifyEmail'));
const AcceptInvite = lazy(() => import('../../views/auth/AcceptInvite'));
const ShopifyAppStore = lazy(() => import('@views/other/ShopifyAppStore'))
const CampaignPreview = lazy(() => import('@views/main/Campaign/CampaignPreview'))
const AutopilotPlayground = lazy(() => import('@views/AutopilotPlayground/index'))
const Playground2 = lazy(() => import('@views/AutopilotPlayground/Playground2'))
const Playground3 = lazy(() => import('@views/AutopilotPlayground/Playground3'))

const NonAdminRoutes = [
    <OutsideRoute path="/shopify-registration" component={ShopifyRegistration} key={1} />,
    <OutsideRoute path="/shopify/finalize" component={ShopifyCallback} key={2} />,
    <OutsideRoute path="/register/shopify/:key" component={Register} key={3} />,
    <OutsideRoute path="/register" component={Register} publicRoute key={4} />,
    <OutsideRoute path="/verify-email" component={VerifyEmail} key={5} />,
    <OutsideRoute path="/accept-invite" component={AcceptInvite} key={6} />,
    <Route path="/woocommerce" component={Woocommerce} key={7} />,
    <Route path="/woocommerce/auth" component={Woocommerce} key={8} />,
    <Route path="/campaign-preview" component={CampaignPreview} key={9} />,
    <Route path="/shopify-install" component={ShopifyAppStore} key={10} />,
]

const MyRouter = () => {

    return (
        <Router history={history}>
            <QueryChecker />
            <QueryParamProvider ReactRouterRoute={Route}>
                <Suspense fallback={<SuspenseLoading />}>
                    <Switch>
                        {/* <OutsideRoute path="/maintenance" component={MaintenancePage} />
                        <Redirect to="/maintenance" /> */}
                        {
                            !isAdminApp ? (
                                NonAdminRoutes.map(route => route)
                            ) : null
                        }

                        <OutsideRoute path="/login" component={Login} publicRoute />
                        <OutsideRoute path="/password-reset/:uid/:token" component={PasswordResetConfirm} />
                        <OutsideRoute path="/password-reset" component={PasswordResetRequest} />


                        <OutsideRoute path="/playground_old" component={AutopilotPlayground} />
                        <OutsideRoute path="/playground" component={Playground2} />
                        <OutsideRoute path="/playground_1" component={Playground3} />

                        <PrivateRoute path="/" component={PrivateRouter} />
                    </Switch>
                </Suspense>
            </QueryParamProvider>
        </Router>
    );
}

export default MyRouter

const QueryChecker = () => {
    const location = useLocation();
    const enqueueSnackbar = useStore(store => store.enqueueSnackbar);
    const refreshUser = useStore(store => store.refreshUser);
    const handled = useRef(false);
    const refreshed = useRef(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const company_id = url.searchParams.get('company_id');
        if (company_id) {
            setCurrentCompany(company_id)
            url.searchParams.delete('company_id')
            window.history.replaceState(window.history.state, '', url.toString());
        }

        if (!refreshed.current) {
            refreshUser();
            refreshed.current = true;
        }

        const store_connection_error = url.searchParams.get('store_connection_error');
        if (store_connection_error) {
            if (!handled.current) {
                enqueueSnackbar({
                    message: store_connection_error,
                    options: {
                        variant: 'error', anchorOrigin: {
                            horizontal: 'center',
                            vertical: 'top'
                        },
                        transitionDuration: 5000
                    }
                });

                handled.current = true;
            }
            url.searchParams.delete('store_connection_error')
            window.history.replaceState(window.history.state, '', url.toString());
        }
    }, [location.key])

    return null;

}

