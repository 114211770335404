import OutsideNavbar from "@components/navigation/OutsideNavbar";
import SuspenseLoading from "@components/other/SuspenseLoading";
import { Route, Redirect } from "react-router-dom";
import useStore from "src/store/useStore";



const OutsideRoute = ({ component: RouteComponent, publicRoute, path, ...rest }) => {
    const user = useStore(store => store.user)
    const userLoading = useStore(store => store.userLoading)
    return (

        <Route 
            {...rest}
            path={path}
            render={routeProps => {
            if (publicRoute) {
                if (userLoading) {
                    return <SuspenseLoading />
                }
                return (
                    !!user ? (
                        <Redirect to={"/"} />

                    ) : (
                        <OutsideNavbar> <RouteComponent {...routeProps} /></OutsideNavbar>
                    )
                )
            }

            else {
                return <OutsideNavbar>  <RouteComponent {...routeProps} /></OutsideNavbar>
            }

        }} />


    );
};

export default OutsideRoute