import sleep from '@utils/sleep'
import axios from 'axios'
import history from '@components/router/history';
import useStore from 'src/store/useStore';
import { matchPath } from 'react-router-dom';
import { memoize } from 'lodash';
import isAdminApp from '@utils/isAdminApp';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  timeout: 60000,
})
httpClient.defaults.withCredentials = true
httpClient.defaults.xsrfHeaderName = "X-CSRFTOKEN"
httpClient.defaults.xsrfCookieName = "csrftoken"

let showingNotAuthenticatedWarning = false

const shouldDisplayWarning = memoize((currentPathName) => {

  const publicPaths = ["/login", "/register", "/password-reset", "/verify-email", "/accept-invite", "/shopify-registration", "/shopify-install", "/campaign-preview", "/playground", "playground2"]

  for (let path of publicPaths) {
    let isPublicPath = matchPath(currentPathName, {
      path: path,
      exact: false,
      strict: false
    })
    if (isPublicPath) return false
  }

  return true
})

const errorInterceptor = error => {
  // all the error responses
  switch (error?.response?.status) {
    case 403:
      // window.location.href = `/login`;
      // history.push('/login');
      // useStore.setState({ user: null, userLoading: false });
      // setTimeout(() => {
      //   if (!showingNotAuthenticatedWarning && shouldDisplayWarning(history.location.pathname)) {
      //     useStore.getState().enqueueSnackbar({
      //       message: "You are currently not authenticated, please refresh your page or login again in another tab to continue.",
      //       options: {
      //         variant: "warning",
      //         onEnter: () => {
      //           showingNotAuthenticatedWarning = true
      //         },
      //         onExit: () => {
      //           showingNotAuthenticatedWarning = false
      //         }
      //       }
      //     })
      //   }
      // }, 300)

      break;

    default:
      break;
  }

  if (!error.response && error.message === "Network Error") {
    error.response = { data: { fallback_message: "Failed to connect to the server." } };
  }

  else if (error.code === 'ECONNABORTED') {
    error.response = { data: { fallback_message: "Request timed out" } };
  }

  return Promise.reject(error);
}

const successInterceptor = async (response) => {
  (process.env.NODE_ENV === 'development') && (await sleep(0))
  return response.data;
}

// Set the current company header
const requestInterceptor = (config) => {
  const currentcompany = sessionStorage.getItem('currentcompany') || localStorage.getItem('currentcompany');
  if (currentcompany && !config?.headers?.currentcompany) {
    config.headers.currentcompany = currentcompany;
    config.headers.isbackoffice = isAdminApp;
  }
  return config;
}

httpClient.interceptors.response.use(successInterceptor, errorInterceptor);
httpClient.interceptors.request.use(requestInterceptor);


export default httpClient