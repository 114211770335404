import SuspenseLoading from "@components/other/SuspenseLoading";
import { Redirect, Route, useHistory } from "react-router-dom";
import useStore from "src/store/useStore";


const PrivateRoute = ({ component: RouteComponent, ...rest }) => {

    const user = useStore(store => store.user)
    const userLoading = useStore(store => store.userLoading)
    const history = useHistory();

    // useEffect(() => {
    //     trackPageView(); // To track the first pageview upon load
    //     history.listen(trackPageView); // To track the subsequent pageviews
    // }, [history]);

    // function trackPageView() {
    //     sendAmplitudeData(amplitudeEvents.page_view, { page: window.location.href });
    // }


    return (
        <Route
            {...rest}
            render={routeProps => {
                if (userLoading) {
                    return <SuspenseLoading />
                }
                return (
                    !!user ? (
                        <RouteComponent {...routeProps} />

                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    referrer: rest.location.pathname,
                                    search: rest.location.search
                                }
                            }} />
                    )
                )
            }
            }
        />
    );
};


export default PrivateRoute