import { useEffect } from "react"
import useStore from "src/store/useStore"

export default (props) => {
    const { immediate = false } = props
    const setGlobalLoading = useStore(store => store.setGlobalLoading)

    useEffect(() => {
        setGlobalLoading(true)
        immediate && setGlobalLoading.flush()
        return () => {
            setGlobalLoading(false)
            immediate && setGlobalLoading.flush()
        }
    }, [])
    return null
}